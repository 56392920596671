<template>
  <el-dropdown trigger="click" @command="handleSetSize">
    <div>
      <svg-icon class-name="size-icon" icon-class="size" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="size === 'medium'" command="medium">
        {{ $t('size.medium') }}
      </el-dropdown-item>
      <el-dropdown-item :disabled="size === 'small'" command="small">
        {{ $t('size.small') }}
      </el-dropdown-item>
      <el-dropdown-item :disabled="size === 'mini'" command="mini">
        {{ $t('size.mini') }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  components: {
    SvgIcon
  },
  computed: {
    size() {
      return this.$store.getters.size;
    }
  },
  methods: {
    handleSetSize(size) {
      this.$ELEMENT.size = size;
      this.$store.dispatch('setSize', size);
      this.refreshView();
      this.$message({
        message: 'Switch Size Success',
        type: 'success'
      });
    },
    refreshView() {
      // In order to make the cached page re-rendered
      this.$store.dispatch('delAllCachedViews', this.$route);

      const { fullPath } = this.$route;

      this.$nextTick(() => {
        this.$router.replace({
          path: '/redirect' + fullPath
        });
      });
    }
  }
};
</script>

<style scoped>
.size-icon {
  font-size: 20px;
  cursor: pointer;
  vertical-align: -4px !important;
}
</style>
