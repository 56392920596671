<template>
  <component :is="linkProps(to).is" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script>
// import { isExternal } from '@/utils'

export default {
  props: {
    to: {
      required: true,
      type: String
    }
  },
  methods: {
    isExternalLink(routePath) {
      return false; // isExternal(routePath)
    },
    linkProps(url) {
      if (this.isExternalLink(url)) {
        return {
          href: url,
          is: 'a',
          rel: 'noopener',
          target: '_blank'
        };
      }
      return {
        is: 'router-link',
        to: url
      };
    }
  }
};
</script>
